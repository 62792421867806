@use 'sass:map';
@use '@angular/material' as mat;


.pill-container {
  display: flex;
  align-items: center;

  mat-label {
    margin-right: 10px;
    color: var(--secondary-text-color);
  }
}

.button-group-container {
  width: fit-content;
  margin: 10px 0;
  overflow:hidden;
  border-radius: 50px;
  height: 40px;
  border: var(--pill-item-border);
  &.invalid {
    border: 1px solid var(--mat-form-field-error-text-color);

    .button-group {
      border-right: 1px solid var(--mat-form-field-error-text-color);
      button {
        color: var(--mat-form-field-error-text-color);
      }
    }
  }

  .button-group{
    overflow:hidden;
    display: inline-block;
    border-right: var(--pill-item-border);

    &:last-of-type {
      border: 0;
    }

    button {
      background: var(--pill-background);
      height: 40px;
      margin:0;
      width: 100%;
      min-width: 75px;
      border-radius:0;
      color: var(--primary-text-color);
      font-size: 14px;
      padding: 5px 15px;

      &:hover {
        background-color: rgba(255,255,255,0.3);
      }

      &.active {
        color: var(--primary-button-text-color);

        &:hover {
          background-color: var(--primary-color);
          cursor: pointer;
        }
      }

      &:disabled {
        cursor: default;
        background: var(--disabled);

        &.active {
          color: var(--primary-text-color);
          background-color: var(--disabled-active) !important;

          &:hover {
            background-color: var(--primary-color);
            cursor: default;
          }
        }
      }
    }
  }
}

@mixin color($theme) {
 .button-group-container {
    .button-group{
      button {
        &.active {
          background-color: mat.get-theme-color($theme, accent, 900) !important;
        }
      }
    }
  }
}
@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
