@use '../../../node_modules/@angular/material/prebuilt-themes/purple-green.css';
@use '@angular/material' as mat;
@use '../../app/shared/forms/components/pill-button-group-control/pill-button-group.component.scss' as pillButton;

$dark-palette: (
 50: rgba(141, 131, 190, 0.20),
 100: rgba(141, 131, 190, 0.40),
 200: rgba(141, 131, 190, 0.60),
 300: rgba(141, 131, 190, 0.80),
 400: #8D83BE,
 500: #8D83BE,
 600: #8D83BE,
 700: #8D83BE,
 800: #8D83BE,
 900: #8D83BE,
 1000: #8D83BE,
 contrast: (
   50: rgba(black, 0.80),
   100: rgba(black, 0.80),
   200: rgba(black, 0.80),
   300: rgba(black, 0.80),
   400: rgba(black, 0.80),
   500: rgba(black, 0.80),
   600: rgba(black, 0.80),
   700: rgba(black, 0.80),
   800: rgba(black, 0.80),
   900: rgba(black, 0.80),
   1000: rgba(black, 0.80),
 ),
  A50: rgba(0, 179, 176, 0.20),
  A100: rgba(0, 179, 176, 0.40),
  A200: rgba(0, 179, 176, 0.60),
  A300: rgba(0, 179, 176, 0.80),
  A400: rgba(0, 179, 176, 1),
  A500: rgba(0, 179, 176, 1),
  A600: rgba(0, 179, 176, 1),
  A700: rgba(0, 179, 176, 1),
  A800: rgba(0, 179, 176, 1),
  A900: rgba(0, 179, 176, 1),
  A1000: rgba(0, 179, 176, 1),
);

$my-primary: mat.define-palette($dark-palette, 400);
$my-accent: mat.define-palette($dark-palette, A400, A300, A200, A100);

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent
  ),
  density: 0
));

// Apply the dark theme by default
@include mat.core-theme($dark-theme);
@include mat.all-component-themes($dark-theme);
@include pillButton.theme($dark-theme);

:root {
  // Default styles
  --error-background-image: url(../images/error-page_darkmode.jpg);
  --page-background-color: #171717;
  --border-color: rgba(255, 255, 255, 0.4);
  --primary-color: var(--clayton-dusk);
  --primary-text-color: var(--white);
  --secondary-text-color: #AFAFAF;
  --logo-color: var(--primary-color);

  // Panels
  --panel-background-color: #1E1E1E;
  --panel-heading-background-color: var(--primary-color);
  --panel-border-color: rbga(255,255,255, 0%);
  --panel-heading-text-color: #1E1E1E;

  // Tables
  --row-hover: var(--primary-color);
  --row-text: var(--primary-text-color);
  --row-secondary-text: var(--secondary-text-color);
  --row-text-hover: var(--black);
  --row-active-background: rgba(255,255,255,0);
  --row-active-border: var(--primary-color);
  --row-active-text: var(--white);

  // Navigation
  --nav-menu-background: var(--background-dark);
  --nav-item-background-hover: rgba(255,255,255,0.08);
  --nav-item-active-border: 5px solid var(--nav-item-active-border-color);
  --nav-item-active-border-color: var(--primary-color);
  --nav-item-active-text-color: var(--primary-color);
  --nav-item-text-color: var(--off-white);

  // Page Header
  --page-header-background: var(--background-dark);

  // Buttons
  --primary-button-background-color: var(--primary-color);
  --primary-button-text-color: var(--black);

  --primary-button-hover-background-color: var(--nav-item-background-hover);
  --primary-button-hover-text-color: var(--white);

  --secondary-button-background-color: #949494;
  --secondary-button-text-color: var(--white);
  --secondary-button-border: var(--content-border);

  --button-disabled-color: #45454593;

  //menu
  --menu-text-hover-color: var(--black);

  //Pills
  --pill-background: #424242;
  --pill-button-active-color: mat.get-theme-color($theme, accent, 500)
  --pill-border: 1px solid rgba(255, 255, 255, 0.00);
  --pill-item-active: var(--primary-color);
  --pill-item-border: 1px solid #595959;

  //Form Fields
  --form-field-border: 1px solid #ABABAB;
}
